import React, { Component } from 'react'
import { Link } from 'gatsby'

import styles from './clients/tool.module.sass'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import Layout from '../components/layout'
import SEO from '../components/seo'

class ClientAccessPage extends Component {
   constructor(props) {
      super(props)

      this.handleAdvisorChange = this.handleAdvisorChange.bind(this)

      this.state = {
         advisor: 'none',
      }
   }

   handleAdvisorChange(e) {
      this.setState({ advisor: e.target.value })
      console.log(e.target.value)
   }

   render() {
      return (
         <Layout>
            <SEO title="Schedule an Appointment" />
            <div
               className={[
                  'page',
                  styles.page__tool,
                  styles.page__schedule,
               ].join(' ')}>
               <div className={['page--header', styles.page__header].join(' ')}>
                  <h1 className={['page--title', styles.page__title].join(' ')}>
                     Schedule an Appointment
                  </h1>
                  {/* <p className={['page--subheader', styles.page__subheader].join(' ')}></p> */}
               </div>
               <div className={styles.tool__content}>
                  <div className={styles.tool__intro}>
                     <div className={styles.left}>
                        <p>
                           At SPC, we believe it is important to stay true to
                           our motto of providing personalized financial
                           services on a human level. As a result, we now offer
                           the ability for our clients and prospects to schedule
                           appointments with our tax-integrated
                           <Link to="/legal/disclaimers#tax">*</Link> team of
                           wealth advisors online! With just a few clicks, you
                           can quickly find a time that works best for you.
                        </p>
                     </div>
                     <div className={styles.right}>
                        <form>
                           <div
                              className={[
                                 styles.form__element,
                                 styles.contact__name,
                              ].join(' ')}>
                              <label htmlFor="advisor">
                                 Select Your Advisor
                                 <select
                                    id="advisor"
                                    onChange={this.handleAdvisorChange}
                                    value={this.state.advisor}>
                                    <option value="none" selected>
                                       Click to find your advisor &rarr;
                                    </option>
                                    <option value="esella">
                                       Edward G. Sella, CPA/PFS, CFP®
                                    </option>
                                    <option value="rmcglynn">
                                       Rory M. McGlynn, CFP®
                                    </option>
                                    <option value="mgilchrist">
                                       Matthew E. Gilchrist, CPA/PFS, CFP®
                                    </option>
                                    <option value="jperlman">
                                       Jason C. Perlman, CFP®
                                    </option>
                                    <option value="pmantua">
                                       Philip J. Mantua, Jr., M.B.A., CFP®
                                    </option>
                                    <option value="atheobald">
                                       Andrew A. Theobald, CFP®
                                    </option>
                                 </select>
                              </label>
                           </div>
                           {this.state.advisor !== 'none' ? (
                              <>
                                 <div className={styles.form__actions}>
                                    <a
                                       href={
                                          '//calendly.com/spc-' +
                                          this.state.advisor
                                       }
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       className="button button--primary button--full button--rounded button--shadow button--has-icon">
                                       Find a Time <span>&rarr;</span>
                                    </a>
                                 </div>

                                 <div className={styles.form__disclaimer}>
                                    <p>
                                       Proceeding will direct you to Calendly,
                                       our online scheduling service provider.
                                    </p>
                                 </div>
                              </>
                           ) : (
                              <div className={styles.form__disclaimer}>
                                 <p>
                                    If your advisor is not shown, please{' '}
                                    <Link to="/support">contact us</Link>. We
                                    are happy to assist!
                                 </p>
                              </div>
                           )}
                        </form>

                        <br />

                        <div className={styles.info__outlined} role="alert">
                           <strong>
                              <FontAwesomeIcon icon={faInfoCircle} /> In-Person
                              Meetings Available
                           </strong>
                           <br />
                           <p>
                              We currently offer in-person meetings at our
                              corporate office in Rockville, MD. If you prefer,
                              the option for teleconferencing solutions will
                              remain in place.
                              <br />
                              <br />
                              We are continually monitoring the situation. If
                              you have any questions, please{' '}
                              <Link to="/support/">contact our office</Link>.
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </Layout>
      )
   }
}

export default ClientAccessPage
